export default {

    download: 'Hành trình thể chất và tinh thần, cuộc hẹn mát-xa diễn ra như dự kiến, đưa bạn khám phá sự yên tĩnh và thoải mái bên trong.',
    about_us: 'Về chúng tôi',
    t_h_title: 'Massage và trị liệu',
    t_h_content: 'Trải nghiệm nghệ thuật massage và điều trị kiệt sức về thể chất và tinh thần như đã hứa. Chúng tôi cam kết cung cấp dịch vụ massage tại nhà chuyên nghiệp để thư giãn và trẻ hóa cơ thể và tâm trí của bạn.',
    t_t_m: 'Trí tuệ truyền thống',
    t_t_t: 'Massage Thái có một lịch sử lâu dài, tích hợp tri thức cổ xưa để làm dịu cơ thể và tâm hồn bằng các kỹ thuật độc đáo.',
    t_t_m_2: 'Điều trị kinh lạc',
    t_t_t_2: 'Điều chỉnh kinh lạc: Massage với kỹ thuật tinh xảo giúp làm dịu các kinh lạc, kích thích tuần hoàn máu, và làm sống lại sức sống',
    t_t_m_3: 'Thư giãn tâm thần và cơ thể',
    t_t_t_3: 'Thư giãn tinh thần và cơ thể: Tận hưởng niềm vui về tinh thần và thể chất mang lại bởi việc massage trong sự thoải mái của ngôi nhà của bạn, và thư giãn hoàn toàn.',
    h_s_e: 'Trải nghiệm đặc trưng của Đông Nam Á',
    h_s_e_m: 'Global Rendezvous mang đến cho bạn dịch vụ mát-xa sang trọng và chăm sóc chuyên sâu, mang lại trải nghiệm toàn diện về sức khỏe và hạnh phúc.',
    h_s_t: 'Massage truyền thống Thái Lan',
    h_s_t_1: 'Lý liệu Đông Nam Á',
    h_s_t_2: 'Truyền thống cổ xưa',
    h_s_t_3: 'Dịch vụ hạng sao',
    h_s_t_4: 'Sự Thư giãn của Cơ thể và Tâm trí',
    h_s_t_5: 'Kỹ Thuật Lão Luyện',
    d_a_g_t: 'Hành trình thể chất và tinh thần, cuộc hẹn mát-xa diễn ra như dự kiến, đưa bạn khám phá sự yên tĩnh và thoải mái bên trong.',
    d_o_t: 'Thưởng thức những liệu pháp mát-xa đặc trưng của Đông Nam Á mà không cần phải ra khỏi nhà của bạn.',
    d_o_q_t: 'Tải ứng dụng ngay bây giờ và thưởng thức dịch vụ mát-xa tận nơi bất cứ lúc nào, bất cứ đâu, biến nó thành một chuyến đi khó quên trong cuộc sống của bạn.',
    q_s_f: 'Các thợ mát-xa kinh nghiệm của chúng tôi đều được đào tạo toàn diện về mát-xa chuyên nghiệp và liệu pháp bằng thảo dược, mang lại cho bạn trải nghiệm mát-xa Đông Nam Á đích thực nhất. Hiệu quả giúp bạn giảm căng thẳng và mệt mỏi, giải phóng hoàn toàn áp lực tích tụ.',
    t_o_s: 'Trải nghiệm spa trực tuyến',
    
    ab_t_t: "Trong xã hội hiện đại, tình trạng sức khỏe không tốt.",
    ab_t_b: "Trong xã hội hiện đại, tình trạng sức khỏe không tốt đã trở thành một trong những thách thức chính đe dọa sự phúc lợi của con người. Tình trạng này, nằm giữa bệnh tật và sức khỏe, ảnh hưởng đến 70% dân số. Tuy nhiên, đối với phần lớn người, sức khỏe không tốt thường biểu hiện dưới dạng các bệnh mãn tính không thể chữa trị nhanh chóng bằng thuốc đơn giản. Thay vào đó, nó yêu cầu quản lý thông qua các nguyên tắc của chăm sóc sức khỏe, điều trị và chăm sóc sức khỏe. Điều này hòa mình hoàn hảo với triết lý của công ty chúng tôi, thúc đẩy chúng tôi phát triển và giới thiệu các sản phẩm hoặc dịch vụ phù hợp để đáp ứng nhu cầu này.",
    ab_t_t_1: "Cung cấp dịch vụ an toàn, tiện lợi và chuyên nghiệp cho khách hàng.",
    ab_t_b_1: "Tình trạng này, nằm giữa bệnh và sức khỏe, là một trong những mối đe dọa lớn đối với sức khỏe của con người, ảnh hưởng nghiêm trọng đến 70% dân số. Tuy nhiên, đối với đa số mọi người, sức khỏe không tối ưu biểu hiện dưới dạng các bệnh mãn tính không thể chữa trị nhanh chóng bằng cách tiêm hoặc dùng thuốc. Nó đòi hỏi quản lý thông qua các nguyên tắc về sức khỏe, điều trị và chăm sóc sức khỏe. Điều này tương thích với triết lý của công ty chúng tôi, dẫn đến sự xuất hiện của nó.",
    ab_t_t_2: "Về chúng tôi",
    ab_t_b_2: "Chúng tôi mang đến sự ấm áp và chân thành cho những người theo đuổi sức khỏe và cuộc sống chất lượng cao, giúp giảm bớt áp lực của cuộc sống và công việc bận rộn, giúp cơ thể và tâm trí của bạn hoàn toàn thư giãn. Cam kết mang đến dịch vụ xuất sắc, mục tiêu của chúng tôi là tạo ra một thương hiệu sức khỏe tập trung vào việc tận hưởng sức khỏe và cuộc sống, phục vụ một đối tượng khách hàng rộng lớn hơn. Đó là mục đích mà chúng tôi đã thành lập.",

    ft_t: 'trang đầu',
    apple_down: "Apple tải xuống",
    android_down: 'Tải xuống Android',
    user_app: 'Tải xuống máy khách',
    technician_app: 'Kỹ thuật viên tải xuống'
    
}