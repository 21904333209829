export default {

    download: '身心之旅，按摩预约如约而至，带您探索内心的宁静与舒适。',
    about_us: '关于我们',
    t_h_title: '按摩与治疗',
    t_h_content: '“如约环球“"体验按摩的艺术，治疗身心疲惫。我们致力于提供专业的上门按摩服务，让您身心放松，焕发活力.',
    t_t_m: '传统智慧',
    t_t_t: '泰式按摩源远流长，融合古老智慧，以独特的手法舒缓身心。',
    t_t_m_2: '经络调理',
    t_t_t_2: '精湛技艺带来的按摩，有助于舒缓经络，促进血液循环，焕发活力。',
    t_t_m_3: '身心放松',
    t_t_t_3: '在家中舒适的环境中，享受按摩带来的身心愉悦，彻底放松。',
    h_s_e: '东南亚特色体验',
    h_s_e_m: '如约环球让你感受奢华的按摩服务以及特色护理，为你提供全身心的健康享受体验',
    h_s_t: '泰式传统按摩',
    h_s_t_1: '东南亚理疗',
    h_s_t_2: '古法传统',
    h_s_t_3: '星级服务',
    h_s_t_4: '身心放松',
    h_s_t_5: '熟练手法',
    d_a_g_t: '身心之旅，按摩预约如约而至，带您探索内心的宁静与舒适。',
    d_o_t: '无需出门，即可享受到全身心的东南亚特色按摩',
    d_o_q_t: '立即下载应用，来次随时随地的上门按摩服务享受，成为你生活中一次难忘的奇妙旅程',
    q_s_f: '我们专业的资深东南亚技师均接受专业按摩及草本治疗的全方位训练，为您提供最正宗的东南亚按摩。有效为你舒展绷紧疲劳，彻底释放积累的压力。',
    t_o_s: '在线水疗体验',
    
    ab_t_t: "在现代社会，健康状况不佳",
    ab_t_b: "在现代社会，亚健康已成为威胁人类福祉的主要挑战之一。这种介于疾病和健康之间的状况严重影响了 70% 的人口。然而，对于大多数人来说，次优健康表现为无法通过简单药物快速治愈的慢性疾病。相反，它需要通过保健、治疗和保健的原则进行管理。这与我们的公司理念无缝契合，促使我们开发和推出相应的产品或服务来满足这一需求。",
    ab_t_t_1: "为客户提供安全、便捷、专业的服务。",
    ab_t_b_1: "这种介于疾病和健康之间的疾病是对人类健康的主要威胁之一，严重影响了 70% 的人口。然而，对于大多数人来说，次优健康表现为无法通过注射或药物快速治愈的慢性疾病。它需要通过养生、治疗和保健的原则进行管理。这与我们公司的理念相一致，从而导致了它的出现。",
    ab_t_t_2: "关于我们",
    ab_t_b_2: "我们为追求健康和高品质生活的人们送去温暖和真诚，为面临繁忙工作和生活压力的人们缓解疲劳，让您的身心得到彻底的放松。秉承卓越服务的承诺，我们的目标是创建一个专注于享受健康和生活的健康品牌，服务于更广泛的公众。这就是我们成立的目的。",

    ft_t: '首页',
    apple_down: "苹果下载",
    android_down: 'Android 下载',
    user_app: '用户端下载',
    technician_app: '技师端下载'
    
}