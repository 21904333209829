import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const isMobile = document.body.clientWidth <= 756;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => isMobile ? import("../views/mobile/HomeView.vue") : import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => isMobile ? import('../views/mobile/AboutView.vue') : import("../views/AboutView.vue"),
  },
  {
    path: '/apple',
    name: 'apple',
    component: () => import('../views/mobile/AppleDown.vue')
  }, {
    path: '/android',
    name: 'android',
    component: () => import('../views/mobile/AndroidDown.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
