export default {

    download: 'การเดินทางทั้งกายและใจ นัดนวด ตามกําหนด นําท่านสํารวจความเงียบสงบและความสบายภายใน.',
    about_us: 'เกี่ยวกับเรา',
    t_h_title: 'การนวดและการบำบัด',
    t_h_content: 'สัมผัสศิลปะการนวด และบําบัดอาการอ่อนเพลียทั้งกายและใจตามที่สัญญาไว้. เรามุ่งมั่นที่จะให้บริการนวดที่บ้านอย่างมืออาชีพเพื่อผ่อนคลายและฟื้นฟูร่างกายและจิตใจของคุณ.',
    t_t_m: 'ปรีชาญาณดั้งเดิม',
    t_t_t: 'นวดไทยมีประวัติศาสตร์ยาวนาน ผสมปัญญาโบราณเพื่อผ่อนคลายร่างกายและใจด้วยเทคนิคที่เป็นเอกลักษณ์',
    t_t_m_2: 'การปรับสมดุลทางนวด',
    t_t_t_2: 'การปรับสมดุลเส้นลม: นวดด้วยทักษะแฝงช่วยปรับสมดุลส่วนต่อส่วน, กระตุ้นการไหลเวียนของเลือด, และปลดปล่อยความสดชื่นให้กับชีวิต',
    t_t_m_3: 'การผ่อนคลายจิตใจและร่างกาย',
    t_t_t_3: 'การผ่อนคลายทั้งร่างกายและจิตใจ: สนุกกับความพอใจทางกายและจิตใจที่นำเสนอโดยการนวดในบ้านของคุณ และผ่อนคลายอย่างสมบูรณ์',
    h_s_e: 'ประสบการณ์พิเศษที่เป็นเอกลักษณ์ของเอเชียตะวันออกเฉียงใต้',
    h_s_e_m: 'Global Rendezvous ทำให้คุณได้รับบริการนวดที่หรูหราและดูแลพิเศษที่ให้คุณได้สัมผัสประสบการณ์การดูแลสุขภาพที่ครอบคลุมทั้งร่างกายและจิตใจ',
    h_s_t: 'นวดแผนโบราณไทย',
    h_s_t_1: 'การรักษาแบบเอเชียตะวันออกเฉียง',
    h_s_t_2: 'ดัชนีประเพณีโบราณ',
    h_s_t_3: 'บริการระดับดาว',
    h_s_t_4: 'การผ่อนคลายร่างกายและจิตใจ',
    h_s_t_5: 'ท่ามือชำนาญ',
    d_a_g_t: 'การเดินทางทั้งกายและใจ นัดนวด ตามกําหนด นําท่านสํารวจความเงียบสงบและความสบายภายใน.',
    d_o_t: 'สนุกกับการนวดแบบเอเชียตะวันออกเฉียงใต้ที่แท้จริงโดยไม่ต้องออกจากบ้านของคุณ',
    d_o_q_t: 'ดาวน์โหลดแอปพลิเคชันทันทีและเพลิดเพลินกับบริการนวดที่ประตูบ้านตามคำขอได้ตลอดเวลาทุกที่ทุกเวลา ทำให้เป็นการเดินทางที่น่าจดจำในชีวิตของคุณ',
    q_s_f: 'นักบำบัดชาวเอเชียตะวันออกเฉียบพลันและมีประสบการณ์ได้รับการฝึกอบรมอย่างเต็มรูปแบบในการนวดมืออาชีพและการรักษาด้วยสมุนไพร เพื่อให้คุณได้รับการนวดเอเชียตะวันออกที่แท้จริงที่สุด ช่วยบรรเทาความตึงเครียดและความเหนื่อยล้าอย่างมีประสิทธิภาพ ปล่อยปัดความกดดันที่สะสม',
    t_o_s: 'ประสบการณ์สปาออนไลน์',
    
    ab_t_t: "ในสังคมยุคใหม่ สุขภาพไม่ดี",
    ab_t_b: "ในสังคมยุคใหม่ สุขภาพที่ไม่ดีอยู่เป็นหนึ่งในความท้าทายหลักที่เป็นอันตรายต่อความเป็นอยู่ของมนุษย์ สภาวะนี้ที่ตั้งอยู่ระหว่างโรคและสุขภาพ มีผลกระทบต่อประชากร 70% อย่างมาก อย่างไรก็ตาม สำหรับส่วนใหญ่ของบุคคล สุขภาพที่ไม่ดีจะแสดงออกเป็นโรคเรื้อรังที่ไม่สามารถรักษาได้อย่างรวดเร็วด้วยยาที่เรียบง่าย แต่ต้องการการจัดการผ่านทางหลักการดูแลสุขภาพ การรักษา และการดูแลสุขภาพ นี่เข้ากันได้อย่างลื่อคลายกับปรัชญาของบริษัทของเรา ทำให้เราพัฒนาและนำเสนอผลิตภัณฑ์หรือบริการที่เหมาะสมเพื่อตอบสนองความต้องการนี้ ",
    ab_t_t_1: "ให้บริการแก่ลูกค้าอย่างปลอดภัย สะดวก และมืออาชีพ",
    ab_t_b_1: "โรคที่อยู่ระหว่างโรคและสุขภาพเป็นหนึ่งในอุปสรรคใหญ่ต่อสุขภาพของมนุษย์ ที่มีผลกระทบรุนแรงต่อประชากร 70% อย่างไรก็ตาม สำหรับส่วนมากของคน สุขภาพไม่ดีแสดงออกเป็นโรคเรื้อรังที่ไม่สามารถรักษาได้อย่างรวดเร็วด้วยการฉีดยาหรือยา มันต้องการการจัดการผ่านทางหลักการเพื่อการเลี้ยงดู การรักษา และการดูแลสุขภาพ ซึ่งตรงกับความคิดเห็นของบริษัทของเรา นำไปสู่การเกิดขึ้นของมัน",
    ab_t_t_2: "เกี่ยวกับเรา",
    ab_t_b_2: "เรานำความอบอุ่นและความจริงใจมาส่งให้กับผู้ที่ตามหาสุขภาพและการดำเนินชีวิตที่มีคุณภาพสูง ให้ความปลอดภัยแก่บุคคลที่ต้องเผชิญกับกดดันจากการทำงานและชีวิตที่คับแคบ ทำให้ร่างกายและจิตใจของคุณได้รับการผ่อนคลายอย่างสมบูรณ์ เรามุ่งมั่นที่จะให้บริการอย่างมีเกียรติยศ จุดมุ่งหมายของเราคือการสร้างแบรนด์สุขภาพที่มุ่งเน้นการเพลิดเพลินกับสุขภาพและชีวิต ให้บริการกับผู้คนหลากหลายกว่านี้ เป็นวัตถุประสงค์ที่เราจัดตั้งขึ้นมา",

    ft_t: 'หน้าแรก',
    apple_down: "ดาวน์โหลดแอปเปิ้ล",
    android_down: 'Android ดาวน์โหลด',
    user_app: 'ดาวน์โหลดไคลเอนต์',
    technician_app: 'ดาวน์โหลดช่าง'
    
}