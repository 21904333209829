export default {

    download: 'Journey of Body and Mind, massage appointments arrive as scheduled, taking you to explore inner peace and comfort.',
    about_us: 'About US',
    t_h_title: 'Massage & Treatment',
    t_h_content: '“Global Rendezvous“ Experience the art of massage, healing both body and mind fatigue. We are committed to providing professional in-home massage services, leaving you rejuvenated and refreshed.',
    t_t_m: 'Traditional wisdom',
    t_t_t: "Thai massage has a long history, integrating ancient wisdom to soothe the body and mind with unique techniques.",
    t_t_m_2: 'Meridian regulation',
    t_t_t_2: "Massage with exquisite skills helps to soothe the meridians, promote blood circulation, and rejuvenate vitality.",
    t_t_m_3: 'Mind and body relaxation',
    t_t_t_3: "Enjoy the physical and mental pleasure brought by massage in the comfort of your home, and relax completely.",
    h_s_e: 'Southeast Asian Specialty Experience',
    h_s_e_m: 'Global Rendezvous offers you luxurious massage services and specialized care, providing you with a holistic experience of health and well-being.',
    h_s_t: 'Traditional Thai Massage',
    h_s_t_1: 'Southeast Asian Therapy',
    h_s_t_2: 'Ancient Traditional',
    h_s_t_3: 'Star-rated Service',
    h_s_t_4: 'Relaxation of Body and Mind',
    h_s_t_5: 'Skillful Techniques',
    d_a_g_t: 'Journey of Body and Mind, massage appointments arrive as scheduled, taking you to explore inner peace and comfort.',
    d_o_t: 'Enjoy authentic Southeast Asian massages without leaving your home.',
    d_o_q_t: 'Download the app now and enjoy on-demand doorstep massage services anytime, anywhere, making it an unforgettable journey in your life.',
    q_s_f: 'Our professional and experienced Southeast Asian therapists undergo comprehensive training in professional massage and herbal therapy, providing you with the most authentic Southeast Asian massage. Effectively easing tension and fatigue, thoroughly releasing accumulated stress.',
    t_o_s: 'Online spa experience',

    ab_t_t: "In modern society, health conditions are poor.",
    ab_t_b: "In modern society, suboptimal health has emerged as one of the primary challenges threatening human well-being. This condition, situated between illness and health, significantly affects 70% of the population. However, for most individuals, suboptimal health manifests as chronic conditions that cannot be quickly cured with simple medication. Instead, it requires management through principles of healthcare, treatment, and well-being. This aligns seamlessly with our company philosophy, prompting us to develop and introduce products or services to meet this demand.",
    ab_t_t_1: "Providing customers with safe, convenient, and professional services.",
    ab_t_b_1: "This condition, which lies between illness and health, is one of the major threats to human health, severely affecting 70% of the population. However, for most people, suboptimal health manifests as chronic diseases that cannot be quickly cured by injections or medication. It requires management through principles of wellness, treatment, and healthcare. This aligns with our company's philosophy, leading to its emergence.",
    ab_t_t_2: "About Us",
    ab_t_b_2: "We deliver warmth and sincerity to those who pursue health and high-quality living, providing relief to individuals facing the pressures of busy work and life, allowing your body and mind to achieve complete relaxation. Committed to excellence in service, our goal is to create a health brand focused on enjoying wellness and life, serving a wider audience. This is the purpose for which we were founded.",

    ft_t: 'Home',
    apple_down: "Apple Download",
    android_down: 'Android Download',
    user_app: 'User Download',
    technician_app: 'Technician Download'



}