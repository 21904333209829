export default {

    download: 'ដំណើរផ្លូវកាយ និងផ្លូវចិត្ត ការណាត់ជួបម៉ាស្សាមកតាមការគ្រោងទុក ដោយនាំអ្នកឱ្យស្វែងយល់ពីភាពស្ងប់ស្ងាត់ខាងក្នុង និងការលួងលោម។',
    about_us: 'អំពីយើង។',
    t_h_title: 'ម៉ាស្សា និងព្យាបាល',
    t_h_content: 'ទទួលបានបទពិសោធន៍សិល្បៈនៃការម៉ាស្សា និងព្យាបាលការហត់នឿយផ្លូវកាយ និងផ្លូវចិត្ត ដូចដែលបានសន្យា។យើងប្តេជ្ញាផ្តល់សេវាម៉ាស្សាតាមផ្ទះប្រកបដោយវិជ្ជាជីវៈ ដើម្បីសម្រាក និងធ្វើឱ្យរាងកាយ និងចិត្តរបស់អ្នកមានភាពរស់រវើកឡើងវិញ។',
    t_t_m: 'ប្រាជ្ញាប្រពៃណី។',
    t_t_t: 'ម៉ាស្សារបស់ថៃមានប្រវត្តិយូរលង់ណាស់មកហើយ ដោយបញ្ចូលប្រាជ្ញាបុរាណ ដើម្បីបន្ធូរកាយ និងចិត្តដោយបច្ចេកទេសប្លែកៗ។',
    t_t_m_2: 'ម៉ាស៊ីនត្រជាក់ Meridian ។',
    t_t_t_2: 'ការម៉ាស្សាដែលនាំមកដោយគុណធម៌ជួយសម្រាលដល់ meridians ជំរុញចរាចរឈាម និងធ្វើឱ្យមានភាពរស់រវើកឡើងវិញ។',
    t_t_m_3: 'ការសម្រាកនៃចិត្តនិងរាងកាយ។',
    t_t_t_3: 'នៅក្នុងបរិយាកាសដ៏សុខស្រួលនៃផ្ទះរបស់អ្នក រីករាយនឹងការម៉ាស្សាទាំងផ្លូវកាយ និងផ្លូវចិត្ត ហើយសម្រាកទាំងស្រុង។',
    h_s_e: 'បទពិសោធន៍ពិសេសរបស់អាស៊ីអាគ្នេយ៍។',
    h_s_e_m: 'Global Rendezvous អនុញ្ញាតឱ្យអ្នកមានអារម្មណ៍ថាមានសេវាកម្មម៉ាស្សាដ៏ប្រណិត ក៏ដូចជាការព្យាបាលពិសេសដែលផ្តល់ឱ្យអ្នកនូវបទពិសោធន៍រីករាយពេញបេះដូង និងមានសុខភាពល្អ។',
    h_s_t: 'ម៉ាស្សាប្រពៃណីថៃ។',
    h_s_t_1: 'ការព្យាបាលដោយចលនាអាស៊ីអាគ្នេយ៍។',
    h_s_t_2: 'ប្រពៃណីបុរាណ។',
    h_s_t_3: 'សេវាកម្មផ្កាយ។',
    h_s_t_4: 'ការសម្រាកនៃចិត្តនិងរាងកាយ។',
    h_s_t_5: 'បច្ចេកទេសជំនាញ។',
    d_a_g_t: 'ដំណើរផ្លូវកាយ និងផ្លូវចិត្ត ការណាត់ជួបម៉ាស្សាមកតាមការគ្រោងទុក ដោយនាំអ្នកឱ្យស្វែងយល់ពីភាពស្ងប់ស្ងាត់ខាងក្នុង និងការលួងលោម។',
    d_o_t: 'រីករាយជាមួយការម៉ាស្សាពិសេសអាស៊ីអាគ្នេយ៍ពេញបេះដូងដោយមិនចេញទៅក្រៅ។',
    d_o_q_t: 'ទាញយកកម្មវិធីឥឡូវនេះ ហើយរីករាយជាមួយសេវាកម្មម៉ាស្សាពីផ្ទះមួយទៅផ្ទះគ្រប់ពេលវេលា និងគ្រប់ទីកន្លែង ដែលធ្វើឱ្យវាក្លាយជាដំណើរដែលមិនអាចបំភ្លេចបាន និងអស្ចារ្យក្នុងជីវិតរបស់អ្នក។',
    q_s_f: 'អ្នកបច្ចេកទេសអាស៊ីអាគ្នេយ៍ដែលមានជំនាញវិជ្ជាជីវៈរបស់យើងទាំងអស់ទទួលបានការបណ្តុះបណ្តាលគ្រប់ជ្រុងជ្រោយក្នុងការម៉ាស្សាប្រកបដោយវិជ្ជាជីវៈ និងការព្យាបាលរុក្ខជាតិ ដើម្បីផ្តល់ឱ្យអ្នកនូវការម៉ាស្សាអាស៊ីអាគ្នេយ៍ពិតប្រាកដបំផុត។ពង្រីកភាពអស់កម្លាំងរបស់អ្នកយ៉ាងមានប្រសិទ្ធភាព និងបញ្ចេញភាពតានតឹងដែលប្រមូលផ្តុំទាំងស្រុង។',
    t_o_s: 'បទពិសោធន៍ស្ប៉ាតាមអ៊ីនធឺណិត',
    
    ab_t_t: "នៅក្នុងសង្គមសម័យទំនើបសុខភាពមិនល្អ។",
    ab_t_b: "នៅក្នុងសង្គមទំនើប សុខភាពរងបានក្លាយជាបញ្ហាប្រឈមដ៏សំខាន់មួយ ដែលគំរាមកំហែងដល់សុខុមាលភាពរបស់មនុស្ស។ស្ថានភាពនេះដែលធ្លាក់រវាងជំងឺ និងសុខភាព ប៉ះពាល់យ៉ាងធ្ងន់ធ្ងរដល់ 70% នៃប្រជាជន។ទោះជាយ៉ាងណាក៏ដោយ សម្រាប់មនុស្សភាគច្រើន សុខភាពល្អបំផុតបានបង្ហាញខ្លួនឯងថាជាជំងឺរ៉ាំរ៉ៃដែលមិនអាចព្យាបាលបានយ៉ាងឆាប់រហ័សជាមួយនឹងថ្នាំសាមញ្ញ។ផ្ទុយទៅវិញ វាត្រូវតែគ្រប់គ្រងតាមរយៈគោលការណ៍នៃការថែទាំសុខភាព ការព្យាបាល និងការថែទាំសុខភាព។នេះសមយ៉ាងរលូនជាមួយនឹងទស្សនវិជ្ជាសាជីវកម្មរបស់យើង ដែលជំរុញឱ្យយើងបង្កើត និងបើកដំណើរការផលិតផល ឬសេវាកម្មដែលត្រូវគ្នា ដើម្បីបំពេញតម្រូវការនេះ។",
    ab_t_t_1: "ផ្តល់ជូនអតិថិជននូវសេវាកម្មប្រកបដោយសុវត្ថិភាព ងាយស្រួល និងវិជ្ជាជីវៈ។",
    ab_t_b_1: "ជំងឺនេះដែលធ្លាក់រវាងជំងឺ និងសុខភាព គឺជាការគំរាមកំហែងដ៏សំខាន់មួយចំពោះសុខភាពមនុស្ស ដែលប៉ះពាល់យ៉ាងធ្ងន់ធ្ងរដល់ 70 ភាគរយនៃចំនួនប្រជាជន។ទោះជាយ៉ាងណាក៏ដោយ សម្រាប់មនុស្សភាគច្រើន សុខភាពល្អបំផុតបង្ហាញខ្លួនឯងថាជាជំងឺរ៉ាំរ៉ៃដែលមិនអាចព្យាបាលបានយ៉ាងឆាប់រហ័សដោយការចាក់ ឬថ្នាំ។វាត្រូវតែគ្រប់គ្រងតាមរយៈគោលការណ៍សុខភាព ការព្យាបាល និងសុខភាព។នេះគឺស្របទៅនឹងទស្សនវិជ្ជានៃក្រុមហ៊ុនរបស់យើង ដែលនាំទៅដល់ការលេចចេញជារូបរាង។",
    ab_t_t_2: "អំពីយើង។",
    ab_t_b_2: "យើងផ្ញើភាពកក់ក្តៅ និងភាពស្មោះត្រង់ដល់មនុស្សដែលបន្តជីវិតដែលមានសុខភាពល្អ និងគុណភាពខ្ពស់ បំបាត់ភាពអស់កម្លាំងសម្រាប់អ្នកដែលស្ថិតក្រោមភាពតានតឹងពីការងារ និងជីវិតដ៏មមាញឹក និងអនុញ្ញាតឱ្យរាងកាយ និងចិត្តរបស់អ្នកសម្រាកទាំងស្រុង។ជាមួយនឹងការប្តេជ្ញាចិត្តចំពោះឧត្តមភាពក្នុងសេវាកម្ម គោលដៅរបស់យើងគឺបង្កើតម៉ាកយីហោដែលមានសុខភាពល្អ ដែលផ្តោតលើការរីករាយនឹងសុខភាព និងការរស់នៅ បម្រើសាធារណៈជនកាន់តែទូលំទូលាយ។នោះហើយជាអ្វីដែលយើងបានបង្កើតឡើង។",

    ft_t: 'ទំព័រ​មុខ',
    apple_down: "ទាញយក Apple",
    android_down: 'ទាញយក Android',
    user_app: 'ការទាញយករបស់អតិថិជន',
    technician_app: 'ទាញយកអ្នកបច្ចេកទេស'
    
}