import { createI18n } from "vue-i18n"
import zhJson from './language/zh';
import enJson from './language/en';
import thJson from './language/th';
import kmJsom from './language/km';
import viJson from './language/vi';


const messages = {
    zh: zhJson,
    en: enJson,
    th: thJson,
    km: kmJsom,
    vi: viJson
}


export const i18n = createI18n({
    legacy: false,
    messages,
    locale: 'zh'
})