<template>
  <header-view></header-view>
  <div class="content">
    <router-view />
  </div>
</template>

<script lang="ts" setup>

import { defineAsyncComponent } from 'vue';
const isMobile = window.screen.width <= 768;
const HeaderView = defineAsyncComponent(() => isMobile ? import('@/components/mobile/HeaderNav.vue') : import('@/components/HeaderNav.vue'))

window.addEventListener('resize', () => {
  if (window.screen.width <= 768 && !isMobile) {
    location.reload();
  }
  if (window.screen.width > 768 && isMobile) {
    location.reload();
  }
})

</script>

<style lang="scss">
#app {
  height: 100%;
}

.content {
  height: calc(100% - 105px);

  @media screen and (max-width: 768px) {
    height: calc(100% - 14vw);
  }

}
</style>
